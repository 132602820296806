<template>
  <div
    class="app-calendar-plan text-caption"
    :class="{ 'd-none': !item.description }"
  >
    <div class="app-calendar-plan__header">
      <div class="app-calendar-plan__title">
        План
      </div>
    </div>

    <div v-html="formattedDescription" />
  </div>
</template>

<script>
  import { isNull } from 'lodash';
  import { nl2br } from '@/helpers/string';

  export default {
    name: 'UserCalendarRowColPlan',

    props: {
      item: {
        type: [Object],
        default: () => ({}),
      },
    },

    computed: {
      formattedDescription() {
        if (isNull(this.item.description)) {
          return 'Пустой план';
        }

        return nl2br(this.item.description);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarPlan';

  @include calendarPlanCommon;
</style>
