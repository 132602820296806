<template>
  <v-dialog
    v-model="dialogState"
    width="90%"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="400"
  >
    <v-card tile>
      <v-container class="pt-6">
        <v-row>
          <v-col>
            <div class="title">
              {{ form.id ? 'Редактировать событие' : 'Добавить событие' }}
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="form.name"
              label="Название события"
              placeholder=""
              outlined
              dense
              :error-messages="errors.name"
              @focus="errors.name = []"
            />

            <v-text-field
              v-model="form.distance"
              label="Расстояние (км)"
              type="number"
              outlined
              dense
              :error-messages="errors.distance"
              @focus="errors.distance = []"
            />

            <v-menu
              ref="menuDate"
              v-model="menuDate"
              :close-on-content-click="false"
              offset-y
              max-width="280"
              :disabled="fixedDate"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="form.start_date ? moment(form.start_date).format('DD.MM.YYYY') : ''"
                  label="Дата начала"
                  readonly
                  outlined
                  dense
                  :error-messages="errors.start_date"
                  :disabled="fixedDate"
                  v-bind="attrs"
                  v-on="on"
                  @focus="errors.start_date = []"
                />
              </template>

              <v-date-picker
                v-model="form.start_date"
                :active-picker.sync="menuDatePicker"
                width="280"
                @change="menuDateSave"
              />
            </v-menu>

            <v-text-field
              v-model="form.link"
              label="Сайт события"
              placeholder=""
              outlined
              dense
              :error-messages="errors.link"
              @focus="errors.link = []"
            />

            <v-textarea
              v-model="form.description"
              label="Комментарий"
              placeholder=""
              outlined
              dense
              :error-messages="errors.description"
            />
          </v-col>
        </v-row>

        <v-row class="app-dialog-footer">
          <v-col>
            <div class="d-flex">
              <v-btn
                outlined
                rounded
                color="primary"
                @click="dialogState = false"
              >
                Отмена
              </v-btn>

              <v-spacer />

              <v-btn
                rounded
                color="primary"
                :disabled="isLoading"
                :loading="isLoading"
                @click="saveRace"
              >
                Сохранить
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment-timezone';
  import { assign, cloneDeep } from 'lodash';
  import { addEvent } from '@/api/event';

  export default {
    name: 'UserEventDialog',

    props: {
      value: {
        type: Boolean,
        default: false,
      },

      race: {
        type: [Object],
        default: () => ({}),
      },

      fixedDate: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      isLoading: false,
      moment,
      menuDate: false,
      menuDatePicker: 'YEAR',
      menuTime: false,
      form: {
        name: '',
        distance: null,
        start_date: null,
        link: '',
        description: '',
      },
      errors: {},
    }),

    computed: {
      dialogState: {
        get() {
          return this.value;
        },

        set(value) {
          this.$emit('update:value', value);
        },
      },
    },

    methods: {
      menuDateSave(date) {
        this.$refs.menuDate.save(date);
      },

      resetForm() {
        this.form = {
          name: '',
          distance: null,
          start_date: null,
          link: '',
          description: '',
        };
      },

      saveRace() {
        this.isLoading = true;

        let link = this.form.link;

        if (
          link?.length > 0
          && link.substring(0, 6) !== 'http://'
          && link.substring(0, 7) !== 'https://'
        ) {
          link = `https://${link}`;
        }

        const data = {
          id: this.form.id,
          name: this.form.name,
          link,
          distance: this.form.distance,
          start_date: this.$moment(this.form.start_date).format('YYYY-MM-DD HH:mm:ss'),
          description: this.form.description,
        };

        addEvent(data)
          .then((response) => {
            this.dialogState = false;
            this.$emit('addRace', response.data);
            this.resetForm();
          })
          .catch((err) => {
            this.$store.dispatch('snackbar/snackbarOpen', {
              color: 'error',
              contentColor: 'white',
              content: 'Есть ошибки при заполнении формы',
            });

            this.errors = err.response.data.errors
              ? err.response.data.errors
              : {};
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },

    watch: {
      menuDate(val) {
        if (!val) {
          return;
        }

        setTimeout(() => {
          this.menuDatePicker = 'YEAR';
        });
      },

      value(val) {
        if (!val) {
          return;
        }

        if (this.race.id) {
          this.form = cloneDeep(this.race);
          return;
        }

        if (!this.form?.id) {
          this.form = assign(this.form, this.race);
          return;
        }

        this.resetForm();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-dialog-footer {
    position: sticky;
    bottom: 0;
    background: #FFFFFF;
  }
</style>
