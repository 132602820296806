<template>
  <div class="app-calendar-event">
    <div class="app-calendar-event__header">
      <div class="app-calendar-event__title text-caption">
        Событие
      </div>

      <v-menu
        bottom
        left
        nudge-bottom="24"
      >
        <template #activator="{ on }">
          <v-icon
            class="ml-auto"
            color="black"
            size="20"
            :disabled="isDeleting"
            :loading="isDeleting"
            v-on="on"
          >
            mdi-dots-vertical
          </v-icon>
        </template>

        <template>
          <v-list dense>
            <v-list-item
              class="text-caption"
              @click="$emit('edit', item)"
            >
              Редактировать
            </v-list-item>

            <v-list-item
              class="text-caption"
              @click="deleteRace(item)"
            >
              Удалить
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </div>

    <div
      v-if="item.name"
      class="app-calendar-event__name"
    >
      <a
        v-if="item.link"
        :href="item.link"
        target="_blank"
        class="text-caption"
      >
        {{ item.name }}
      </a>

      <span
        v-else
        class="text-caption"
      >
        {{ item.name }}
      </span>
    </div>

    <div
      v-if="item.distance"
      class="lh-1 text-caption mt-2"
    >
      {{ item.distance + ' км' }}
    </div>

    <div
      v-if="item.description"
      class="d-flex align-center c-pointer mb-1 mt-2"
      @click="descriptionOpen = !descriptionOpen"
    >
      <span class="text-caption text-decoration-underline lh-1">
        Комментарий
      </span>

      <v-icon
        color="black"
        size="20"
        class="ml-auto"
      >
        {{ descriptionOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </div>

    <div
      v-if="item.description && descriptionOpen"
      class="text-caption"
      v-html="description"
    />
  </div>
</template>

<script>
  import { deleteEvent } from '@/api/event';
  import { nl2br } from '@/helpers/string';

  export default {
    name: 'UserCalendarRowColEvent',

    props: {
      item: {
        type: [Object],
        default() {
          return null;
        },
      },
    },

    data: () => ({
      descriptionOpen: false,
      isDeleting: false,
    }),

    computed: {
      description() {
        return nl2br(this.item.description);
      },
    },

    methods: {
      deleteRace(race) {
        this.$store.dispatch('confirmer/confirmerAsk', {
          title: 'Подтверждение',
          body: 'Вы действительно хотите удалить событие?',
        })
          .then((confirmation) => {
            if (!confirmation) {
              return;
            }

            this.isDeleting = true;

            deleteEvent(race.id)
              .then((res) => {
                this.day.races.splice(this.day.races.findIndex((el) => el.id === res.data.id), 1);
              })
              .catch(() => {
                this.$store.dispatch('snackbar/snackbarOpen', {
                  color: 'error',
                  contentColor: 'white',
                  content: 'Ошибка при удалении',
                });
              })
              .finally(() => {
                this.isDeleting = false;
              });
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarEvent';
  @include calendarEventCommon;
</style>
