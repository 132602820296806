<template>
  <div class="app-calendar-move">
    <div class="app-calendar-move__content">
      <div class="app-calendar-move__header">
        <v-icon
          color="black"
          size="20"
        >
          {{ iconName }}
        </v-icon>

        <v-icon
          v-if="item.hidden_for_school"
          color="black"
          x-small
          class="app-calendar-move__lock"
        >
          mdi-lock
        </v-icon>

        <v-menu
          bottom
          left
          nudge-bottom="24"
        >
          <template #activator="{ on }">
            <v-icon
              size="20"
              class="ml-auto mb-auto"
              color="black"
              :disabled="isDeletingMove || isHidingMove"
              :loading="isDeletingMove || isHidingMove"
              v-on="on"
            >
              mdi-dots-vertical
            </v-icon>
          </template>

          <template>
            <v-list dense>
              <v-list-item
                class="text-caption"
                @click="toggleHide(item)"
              >
                {{ item.hidden_for_school ? 'Сделать публичной' : 'Сделать приватной' }}
              </v-list-item>

              <v-list-item
                class="text-caption"
                @click="deleteMove(item)"
              >
                Удалить
              </v-list-item>
            </v-list>
          </template>
        </v-menu>
      </div>

      <div class="text-caption font-weight-bold lh-1 my-2">
        <a
          v-if="stravaLinkComp !== '#'"
          :href="stravaLinkComp"
          target="_blank"
        >
          {{ item.name }}
        </a>

        <span v-else>
          {{ item.name }}
        </span>
      </div>

      <div
        v-if="distComp !== 0"
        class="lh-1"
      >
        <v-icon
          color="black"
          small
        >
          mdi-vector-line
        </v-icon>

        <span class="text-caption">
          {{ distComp + ' км' }}
        </span>
      </div>

      <div class="lh-1">
        <v-icon
          color="black"
          small
        >
          mdi-timer-outline
        </v-icon>

        <span class="text-caption">
          {{ timeComp }}
        </span>
      </div>

      <div
        v-if="elevComp !== 0"
        class="lh-1"
      >
        <v-icon
          color="black"
          small
        >
          mdi-trending-up
        </v-icon>

        <span class="text-caption">
          {{ elevComp + ' м' }}
        </span>
      </div>

      <div
        v-if="heartrateComp !== '-'"
        class="lh-1"
      >
        <v-icon
          color="black"
          small
        >
          mdi-heart-outline
        </v-icon>

        <span class="text-caption">
          {{ heartrateComp }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { deleteMove, toggleMoveHiding } from '@/api/move';
  import { convertSecondsToHMMSS, getMoveIconName } from '@/helpers/move';

  export default {
    name: 'UserCalendarRowColMove',

    props: {
      item: {
        type: [Object],
        default: () => ({}),
      },
    },

    data: () => ({
      isDeletingMove: false,
      isHidingMove: false,
    }),

    computed: {
      iconName() {
        return getMoveIconName(this.item?.type);
      },

      timeComp() {
        return this.item
          ? convertSecondsToHMMSS(this.item.elapsed_time)
          : 0;
      },

      distComp() {
        return this.item
          ? Math.trunc(this.item.distance / 100) / 10
          : 0;
      },

      elevComp() {
        return this.item
          ? this.item.total_elevation_gain
          : 0;
      },

      lapsComp() {
        return this.item
          ? JSON.parse(this.item.laps)
          : [];
      },

      stravaLinkComp() {
        return this.item
          ? `https://strava.com/activities/${this.item.strava_id}`
          : '#';
      },

      heartrateComp() {
        let heartrate_sum = 0;

        if (this.item?.average_heartrate) {
          return this.item.average_heartrate;
        }

        this.lapsComp?.forEach((lap) => {
          if (lap.average_heartrate) {
            heartrate_sum += lap.average_heartrate * lap.elapsed_time;
          }
        });

        return heartrate_sum > 0
          ? Math.trunc(heartrate_sum / this.item.elapsed_time)
          : '-';
      },
    },

    methods: {
      deleteMove(move) {
        this.$store.dispatch('confirmer/confirmerAsk', {
          title: 'Подтверждение',
          body: 'Вы действительно хотите удалить тренировку?',
        })
          .then((confirmation) => {
            if (!confirmation) {
              return;
            }

            this.isDeletingMove = true;

            deleteMove(move.id)
              .then(() => {
                this.$emit('deleteMove', move);
              })
              .catch(() => {
                this.$store.dispatch('snackbar/snackbarOpen', {
                  color: 'error',
                  contentColor: 'white',
                  content: 'Ошибка при удалении',
                });
              })
              .finally(() => {
                this.isDeletingMove = false;
              });
          });
      },

      toggleHide(move) {
        this.isHidingMove = true;

        toggleMoveHiding(move.id)
          .then(() => {
            move.hidden_for_school = !move.hidden_for_school;
          })
          .catch(() => {
            this.$store.dispatch('snackbar/snackbarOpen', {
              color: 'error',
              contentColor: 'white',
              content: 'Ошибка',
            });
          })
          .finally(() => {
            this.isHidingMove = false;
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarMove';

  @include calendarMoveCommon;

  .app-calendar-move__lock {
    position: absolute;
    top: 4px;
    left: 28px;
  }
</style>
