<template>
  <div class="app-calendar-column">
    <slot>
      <div class="app-calendar-column__wrapper">
        <div
          class="app-calendar-column__header text-caption"
          :class="currentDay ? 'app-calendar-column__header--current' : ''"
        >
          <div class="d-flex align-center w-100">
            <v-icon
              v-if="currentDay"
              color="white"
              small
              style="margin-left:-4px;"
            >
              mdi-chevron-right
            </v-icon>

            <span :class="{ 'font-weight-bold': currentWeek }">
              {{ date.format('DD MMM') }}
            </span>

            <v-menu
              bottom
              offset-y
              left
            >
              <template #activator="{ on }">
                <v-icon
                  color="white"
                  size="20"
                  class="ml-auto"
                  v-on="on"
                >
                  mdi-menu
                </v-icon>
              </template>

              <v-list dense>
                <v-list-item
                  class="text-caption"
                  @click="openRaceDialog()"
                >
                  Добавить событие
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>

      <div class="app-calendar-column__body">
        <slot name="body">
          <div v-if="!loadingRow">
            <div v-if="day.races.length > 0">
              <user-calendar-row-col-event
                v-for="race in day.races"
                :key="race.id"
                :item="race"
                @edit="openRaceDialog"
              />
            </div>

            <div class="overflow-x-hidden">
              <div v-if="getShowPlans || localShowData">
                <user-calendar-row-col-plan
                  v-for="(item, key) in day.plans"
                  :key="key"
                  :item="item"
                />
              </div>
            </div>

            <div v-if="getShowMoves || localShowData">
              <user-calendar-row-col-move
                v-for="(item, key) in day.moves"
                :key="key"
                :item="item"
                type="move"
                @deleteMove="handleDeleteMove"
              />
            </div>

            <div
              v-if="((!getShowPlans && compPlans.length > 0) || (!getShowMoves && day.moves.length > 0)) && (!localShowData)"
              class="px-1 pt-1 pb-3"
            >
              <div
                v-if="!getShowPlans && compPlans.length > 0"
                class="text-caption lh-1"
              >
                <span
                  class="c-pointer"
                  @click="localShowData = true"
                >{{ compCountPlans }}</span>
              </div>

              <div
                v-if="!getShowMoves && day.moves.length > 0"
                class="text-caption lh-1"
              >
                <span
                  class="c-pointer"
                  @click="localShowData = true"
                >{{ compCountMoves }}</span>
              </div>
            </div>
          </div>

          <div v-else>
            <v-skeleton-loader
              type="image"
              max-height="92"
              class="mb-1"
            />
          </div>
        </slot>
      </div>
    </slot>

    <user-event-dialog
      :value.sync="isRaceDialogOpen"
      :race="selectedRace"
      fixed-date
      @addRace="handleAddRace"
    />
  </div>
</template>

<script>
  import UserCalendarRowColMove from './UserCalendarRowColMove.vue';
  import UserCalendarRowColEvent from './UserCalendarRowColEvent.vue';
  import UserEventDialog from '@/_components/user/UserEventDialog.vue';
  import UserCalendarRowColPlan from '@/_components/user/UserCalendarRowColPlan.vue';
  import { getNoun } from '@/helpers/string';

  export default {
    name: 'UserCalendarRowCol',

    props: {
      day: {
        type: [Object],
        default() {
          return {
            moves: [],
            plans: [],
            races: [],
          };
        },
      },

      date: {
        type: [Object],
        default() {
          return null;
        },
      },

      current: {
        type: [Boolean],
        default: false,
      },

      loadingRow: {
        type: [Boolean],
        default: false,
      },
    },

    components: {
      UserCalendarRowColPlan,
      UserEventDialog,
      UserCalendarRowColEvent,
      UserCalendarRowColMove,
    },

    data: () => ({
      localShowData: false,
      isRaceDialogOpen: false,
      selectedRace: {},
    }),

    computed: {
      getShowPlans() {
        return this.$store.getters['user/getShowPlans'];
      },

      getShowMoves() {
        return this.$store.getters['user/getShowMoves'];
      },

      compPlans: {
        get() {
          const plans = [];

          this.day.plans.forEach((plan) => {
            if (plan.description && plan.description !== '') {
              plans.push(plan);
            }
          });

          return plans;
        },
      },

      compCountPlans() {
        return `${this.day.plans.length} ${getNoun(['план', 'плана', 'планов'], this.day.plans.length)}`;
      },

      compCountMoves() {
        return `${this.day.moves.length} ${getNoun(['тренировка', 'тренировки', 'тренировок'], this.day.moves.length)}`;
      },

      currentWeek() {
        return this.current;
      },

      currentDay() {
        const now = this.$moment();

        const startOfDay = this.date.clone();
        const endOfDay = this.date.clone().add(1, 'days');

        return startOfDay <= now && endOfDay > now;
      },
    },

    methods: {
      handleDeleteMove(move) {
        this.day.moves.splice(this.day.moves.findIndex((el) => el.id === move.id), 1);
      },

      handleAddRace(race) {
        const index = this.day.races.findIndex((item) => item.id === race.id);

        if (index < 0) {
          this.day.races.push(race);
          return;
        }

        this.day.races.splice(index, 1, race);
      },

      openRaceDialog(race = { start_date: this.date.format('YYYY-MM-DD') }) {
        this.selectedRace = race;
        this.isRaceDialogOpen = true;
      },
    },

    watch: {
      getShowPlans() {
        this.localShowData = false;
      },

      getShowMoves() {
        this.localShowData = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarColumn';

  @include calendarColumnCommon;
</style>
