import axios from 'axios';

export const deleteMove = function (id) {
  const url = `/api/move/${id}`;

  return axios.delete(url);
};

export const toggleMoveHiding = function (id) {
  const url = `/api/move/${id}/hide`;

  return axios.put(url);
};
