import axios from 'axios';

export const getEvents = function () {
  const url = '/api/races';

  return axios.get(url);
};

export const addEvent = function (data) {
  const url = '/api/race/add';

  return axios.post(url, data);
};

export const deleteEvent = function (id) {
  const url = `/api/race/${id}`;

  return axios.delete(url);
};
