<template>
  <app-page-primary
    :is-loading="isUserLoading || isUpdating"
    :limited-height="false"
    :body-overflow="true"
  >
    <template>
      <div
        style="max-width: 320px;"
        class="mx-auto col pt-0"
      >
        <v-row>
          <v-col class="shrink">
            <div class="app-avatar d-flex">
              <input
                ref="input"
                class="d-none"
                type="file"
                name="image"
                accept="image/*"
                @input="setImage"
              >

              <v-avatar
                :color="(cropImg !== '' || getUser.avatar || isUserLoading) ? 'secondary' : 'primary'"
                size="80"
                class="c-pointer"
                @click="isUserLoading || isUpdating ? '' : (cropImg !== '' || getUser.avatar) ? showAvatarDialog = true : showFileChooser()"
              >
                <img
                  v-if="cropImg !== ''"
                  :src="cropImg"
                  alt="image"
                >

                <img
                  v-else-if="getUser.avatar"
                  :src="getUser.avatar"
                  alt="image"
                >

                <div
                  v-else-if="!isUserLoading"
                  class="secondary--text text-caption lh-12"
                >
                  Загрузить <br>аватар
                </div>
              </v-avatar>

              <v-dialog
                v-model="showAvatarDialog"
                width="280"
              >
                <v-card>
                  <v-card-text class="pa-2">
                    <div class="mb-2">
                      <v-btn
                        color="black"
                        text
                        small
                        outlined
                        width="100%"
                        @click="showFileChooser"
                      >
                        Изменить аватар
                      </v-btn>
                    </div>

                    <div
                      v-if="cropImg !== '' || getUser.avatar"
                    >
                      <v-btn
                        text
                        small
                        outlined
                        color="error"
                        width="100%"
                        @click="clearImage"
                      >
                        Удалить аватар
                      </v-btn>
                    </div>
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-btn
                      color="black"
                      small
                      outlined
                      text
                      width="100%"
                      @click="showAvatarDialog = false"
                    >
                      Отмена
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="showCropperDialog"
                width="500"
              >
                <v-card>
                  <v-card-text class="pa-2">
                    <vue-cropper
                      ref="cropper"
                      style="max-height: 300px;"
                      :aspect-ratio="1 / 1"
                      :src="imgSrc"
                      preview=".preview"
                    />
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-btn
                      color="black"
                      small
                      outlined
                      text
                      @click="showCropperDialog = false"
                    >
                      Отмена
                    </v-btn>

                    <v-spacer />

                    <v-btn
                      color="success"
                      small
                      outlined
                      text
                      @click="cropImage"
                    >
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>

          <v-col class="pl-0">
            <div class="font-weight-medium lh-12 text-break mt-2">
              {{ getUser.name }}
            </div>

            <div
              v-if="getUser.birthday || getUser.city"
              class="text-caption lh-12 mt-1 mb-auto"
            >
              {{ compUserAge }}{{ getUser.birthday && getUser.city ? ', ' : '' }}{{ getUser.city ? getUser.city : '' }}
            </div>

            <div class="d-flex mt-2">
              <div
                v-if="getUser.strava_user && getUser.strava_user.strava_id"
              >
                <a
                  :href="`https://strava.com/athletes/${getUser.strava_user.strava_id}`"
                  target="_blank"
                >
                  <v-img
                    :src="require('@/assets/logo-strava-small.svg')"
                    width="22"
                    height="22"
                    style="border-radius:4px;"
                    contain
                  />
                </a>
              </div>
            </div>
          </v-col>
        </v-row>

        <div class="my-3">
          <v-btn
            color="black"
            outlined
            small
            rounded
            width="100%"
            :to="{ name: 'user.profile.edit' }"
          >
            Редактировать
          </v-btn>
        </div>

        <v-row>
          <v-col>
            <div>
              <div class="d-flex align-center mb-3">
                <div class="caption mr-1">
                  События
                </div>

                <v-divider />

                <v-btn
                  text
                  outlined
                  rounded
                  x-small
                  class="ml-1"
                  @click="openRaceDialog()"
                >
                  + добавить
                </v-btn>
              </div>

              <user-event-dialog
                :value.sync="isRaceDialogOpen"
                :race="selectedRace"
                @addRace="handleAddRace"
              />

              <user-profile-event
                v-for="race in compFutureRaces"
                :key="race.id"
                :item="race"
                @edit="openRaceDialog"
                @delete="deleteRace"
              />

              <div
                v-if="compPastRaces.length > 0"
                class="c-pointer text-decoration-underline my-1 caption"
                @click="showPastRaces = !showPastRaces"
              >
                {{ showPastRaces ? 'Скрыть прошедшие' : 'Показать прошедшие' }}
              </div>

              <div v-if="showPastRaces">
                <user-profile-event
                  v-for="race in compPastRaces"
                  :key="race.id"
                  :item="race"
                  class="app-past-card"
                  @edit="openRaceDialog"
                  @delete="deleteRace"
                />
              </div>

              <div
                v-if="races.length === 0"
                class="caption my-1"
              >
                Список пуст
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
  </app-page-primary>
</template>

<script>
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import moment from 'moment-timezone';
  import AppPagePrimary from '@/_components/AppPagePrimary.vue';
  import UserProfileEvent from '@/_components/user/UserProfileEvent.vue';
  import UserEventDialog from '@/_components/user/UserEventDialog.vue';
  import { getNoun } from '@/helpers/string';
  import { deleteEvent, getEvents } from '@/api/event';
  import { updateUserAvatar } from '@/api/user';

  export default {
    components: {
      UserEventDialog,
      UserProfileEvent,
      VueCropper,
      AppPagePrimary,
    },

    mounted() {
      this.fetchRaces();
    },

    data: () => ({
      moment,
      showCropperDialog: false,
      showAvatarDialog: false,
      imgSrc: '',
      cropImg: '',
      form: {
        avatar: null,
        avatarAction: 0,
      },
      races: [],
      isRaceDialogOpen: false,
      showPastRaces: false,
      selectedRace: {},
      isUpdating: false,
    }),

    computed: {
      getUser() {
        return this.$store.getters['user/getUser'];
      },

      isUserLoading() {
        return this.$store.state.user.isLoading;
      },

      compUserAge: {
        get() {
          if (!this.getUser.birthday) {
            return '';
          }

          const age = this.moment().diff(this.getUser.birthday, 'years');

          return `${age} ${getNoun(['год', 'года', 'лет'], age)}`;
        },
      },

      compFutureRaces() {
        const races = this.races.filter((item) => moment(item.start_date) >= moment().startOf('day'));

        return races.sort((a, b) => {
          if (moment(a.start_date) > moment(b.start_date)) {
            return 1;
          }

          if (moment(a.start_date) < moment(b.start_date)) {
            return -1;
          }

          return 0;
        });
      },

      compPastRaces() {
        const races = this.races.filter((item) => moment(item.start_date) < moment().startOf('day'));

        return races.sort((a, b) => {
          if (moment(a.start_date) > moment(b.start_date)) {
            return -1;
          }

          if (moment(a.start_date) < moment(b.start_date)) {
            return 1;
          }

          return 0;
        });
      },
    },

    methods: {
      saveProfile() {
        this.isUpdating = true;

        const that = this;

        const formData = new FormData();
        formData.append('avatar_action', this.form.avatarAction);

        if (this.$refs.cropper && (this.form.avatarAction === 1)) {
          this.$refs.cropper.getCroppedCanvas({ maxWidth: 256, maxHeight: 256 }).toBlob((blob) => {
            formData.append('file', blob);
            that.postProfile(formData, true);
          });
        } else {
          that.postProfile(formData);
        }
      },

      postProfile(data, multipart = false) {
        updateUserAvatar(data, multipart)
          .then(() => {
            this.$store.dispatch('user/me');
          })
          .catch(() => {
            this.$store.dispatch('snackbar/snackbarOpen', {
              color: 'error',
              contentColor: 'white',
              content: 'Ошибка при сохранении',
            });
          })
          .finally(() => {
            this.isUpdating = false;
          });
      },

      showFileChooser() {
        this.showAvatarDialog = false;
        this.$refs.input.value = '';
        this.$refs.input.click();
      },

      setImage(e) {
        const file = e.target.files[0];

        if (file.type.indexOf('image/') === -1) {
          return;
        }

        if (typeof FileReader === 'function') {
          const reader = new FileReader();

          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            this.$refs.cropper.replace(event.target.result);
          };

          reader.readAsDataURL(file);

          this.showCropperDialog = true;
        }
      },

      cropImage() {
        this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.avatarAction = 1;
        this.showCropperDialog = false;
        this.saveProfile();
      },

      clearImage() {
        this.showAvatarDialog = false;
        this.cropImg = '';
        this.$refs.input.value = '';

        if (this.getUser.avatar) {
          this.form.avatar = null;
          this.form.avatarAction = -1;
          this.saveProfile();
        } else if (this.form.avatarAction === 1) {
          this.form.avatarAction = 0;
        }
      },

      fetchRaces() {
        getEvents()
          .then((response) => {
            const races = response.data;

            races.forEach((item) => {
              this.$set(item, 'openDescription', false);
            });

            this.races = races;
          });
      },

      openRaceDialog(race = {}) {
        this.selectedRace = race;
        this.isRaceDialogOpen = true;
      },

      handleAddRace(race) {
        const index = this.races.findIndex((item) => item.id === race.id);

        if (index < 0) {
          this.races.push(race);
          return;
        }

        this.races.splice(index, 1, race);
      },

      deleteRace(race) {
        this.$store.dispatch('confirmer/confirmerAsk', {
          title: 'Подтверждение',
          body: 'Вы действительно хотите удалить событие?',
        })
          .then((confirmation) => {
            if (!confirmation) {
              return;
            }

            deleteEvent(race.id)
              .then(() => {
                this.races.splice(this.races.findIndex((el) => el.id === race.id), 1);
              })
              .catch(() => {
                this.$store.dispatch('snackbar/snackbarOpen', {
                  color: 'error',
                  contentColor: 'white',
                  content: 'Ошибка при удалении',
                });
              });
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .my-races-list {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .pulse-table {
    .row {
      &:first-child {
        .col {
          border-top: 1px solid var(--v-black-base);
        }
      }

      .col {
        border-right: 1px solid var(--v-black-base);
        border-bottom: 1px solid var(--v-black-base);

        &:first-child {
          border-left: 1px solid var(--v-black-base);
        }
      }
    }
  }

  .app-past-card {
    opacity: 0.3;
  }

  .app-calendar-race {
    border: 1px solid var(--v-black-base);
    background: var(--v-secondary-base);
    word-break: break-word;
  }

  .profile__add-race {
    cursor: pointer;
    opacity: 0.5;
    border: 1px solid var(--v-black-base);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
</style>
