<template>
  <app-page-primary
    :is-loading="isLoading"
    :limited-height="false"
    :body-overflow="true"
  >
    <template #header>
      <div
        class="d-flex align-center"
      >
        <v-btn
          small
          outlined
          color="icon"
          class="mr-1"
          @click="$router.push({ name: 'user.profile' })"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <div
          class="text-h6 text-break ml-1"
        >
          Настройки
        </div>
        <v-spacer />
      </div>
    </template>

    <template>
      <div>
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <!-- Профиль -->
          <v-expansion-panel>
            <v-expansion-panel-header>Основное</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                class="col col-lg-4 pa-0"
              >
                <v-text-field
                  v-model="form.name"
                  label="Имя и фамилия"
                  placeholder=""
                  outlined
                  dense
                />
                <v-menu
                  ref="menuYear"
                  v-model="menuYear"
                  :close-on-content-click="false"
                  offset-y
                  max-width="280"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="form.birthday ? moment(form.birthday).format('DD.MM.YYYY') : ''"
                      label="Дата рождения"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="form.birthday"
                    :active-picker.sync="menuYearPicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1900-01-01"
                    width="280"
                    @change="menuYearSave"
                  />
                </v-menu>
                <v-text-field
                  v-model="form.city"
                  label="Населенный пункт"
                  placeholder=""
                  outlined
                  dense
                />
                <div
                  class="d-flex justify-center"
                >
                  <v-btn
                    color="primary"
                    class="mt-2"
                    small
                    rounded
                    outlined
                    :disabled="isLoading"
                    @click="cancelProfile"
                  >
                    Отменить
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    class="mt-2"
                    rounded
                    small
                    :disabled="isLoading"
                    @click="saveProfile"
                  >
                    Сохранить
                  </v-btn>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Подключения -->
          <v-expansion-panel>
            <v-expansion-panel-header>Подключения</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                class="col col-lg-4 pa-0"
              >
                <div
                  class="d-flex align-center justify-space-between"
                >
                  <div
                    class="d-flex align-center justify-space-between"
                  >
                    <v-img
                      :max-height="32"
                      :max-width="32"
                      contain
                      position="center center"
                      src="@/assets/logo-strava.png"
                      class="mr-2"
                    />
                    <div
                      class="lh-1"
                    >
                      Strava{{ form.strava_user && form.strava_user.strava_id ? " (подключено)" : "" }}
                    </div>
                  </div>
                  <div>
                    <v-btn
                      color="primary"
                      rounded
                      small
                      class="white--text"
                      :disabled="isLoading"
                      @click="connectStrava"
                    >
                      Подключить
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </app-page-primary>
</template>

<script>
  import moment from 'moment-timezone';
  import AppPagePrimary from '@/_components/AppPagePrimary.vue';
  import { getUser, getUserStravaRedirectUrl, updateUser } from '@/api/user';

  export default {
    components: {
      AppPagePrimary,
    },

    mounted() {
      this.getUser();
    },

    data() {
      return {
        moment,
        menu: false,
        menuYear: false,
        menuYearPicker: 'YEAR',
        panel: [0],
        isLoading: true,
        form: {
          name: '',
          city: null,
          gender: null,
          birthday: null,
        },
      };
    },

    methods: {
      connectStrava() {
        this.isLoading = true;

        getUserStravaRedirectUrl()
          .then((response) => {
            window.location.replace(response.data);
          })
          .catch(() => {
            alert('Ошибка, попробуйте позже');
          })
          .finally(() => {
            this.isLoading = false;
          });
      },

      menuYearSave(date) {
        this.$refs.menuYear.save(date);
      },

      cancelProfile() {
        this.isLoading = true;
        this.getUser();
      },

      saveProfile() {
        this.isLoading = true;

        const formData = new FormData();

        formData.append('name', this.form.name);

        if (this.form.birthday) {
          formData.append('birthday', this.form.birthday);
        }

        if (this.form.city) {
          formData.append('city', this.form.city);
        }

        if (this.form.gender) {
          formData.append('gender', this.form.gender);
        }

        this.postProfile(formData);
      },

      postProfile(data) {
        updateUser(data)
          .then(() => {
            this.$store.dispatch('user/me');
          })
          .catch(() => {
            this.$store.dispatch('snackbar/snackbarOpen', {
              color: 'error',
              contentColor: 'white',
              content: 'Ошибка при сохранении',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      },

      getUser() {
        getUser()
          .then((response) => {
            this.form = response.data;
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },

    watch: {
      menuYear(val) {
        if (!val) {
          return;
        }

        setTimeout(() => {
          this.menuYearPicker = 'YEAR';
        });
      },
    },
  };
</script>

<style lang="scss">
  .my-races-list {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .pulse-table {
    .row {
      &:first-child {
        .col {
          border-top: 1px solid var(--v-black-base);
        }
      }

      .col {
        border-right: 1px solid var(--v-black-base);
        border-bottom: 1px solid var(--v-black-base);

        &:first-child {
          border-left: 1px solid var(--v-black-base);
        }
      }
    }
  }
</style>
