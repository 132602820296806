<template>
  <div class="app-calendar-summary">
    <div class="app-calendar-summary__wrapper">
      <div class="app-calendar-summary__header text-caption">
        <slot name="header" />
      </div>
    </div>

    <div
      v-if="!loadingRow"
      class="app-calendar-summary__body"
    >
      <div>
        <div
          v-if="sumValues.runTime > 0"
          class="mb-1"
        >
          <div class="row no-gutters align-center">
            <div class="col shrink">
              <v-icon
                color="black"
                class="mr-2"
                size="20"
              >
                mdi-run
              </v-icon>
            </div>

            <div class="col">
              {{ convertSecondsToHoursAndMinutes(sumValues.runTime) }}
            </div>

            <div class="col">
              {{ Math.trunc(sumValues.runDistance / 100) / 10 }} км
            </div>

            <div class="col">
              +{{ sumValues.runClimb }} м
            </div>
          </div>
        </div>

        <div
          v-if="sumValues.bikeTime > 0"
          class="mb-1"
        >
          <div class="row no-gutters align-center">
            <div class="col shrink">
              <v-icon
                color="black"
                class="mr-2"
                size="20"
              >
                mdi-bike
              </v-icon>
            </div>

            <div class="col">
              {{ convertSecondsToHoursAndMinutes(sumValues.bikeTime) }}
            </div>

            <div class="col">
              {{ Math.trunc(sumValues.bikeDistance / 100) / 10 }} км
            </div>

            <div class="col">
              +{{ sumValues.bikeClimb }} м
            </div>
          </div>
        </div>

        <div
          v-if="sumValues.skiTime > 0"
          class="mb-1"
        >
          <div class="row no-gutters align-center">
            <div class="col shrink">
              <v-icon
                color="black"
                class="mr-2"
                size="20"
              >
                mdi-ski
              </v-icon>
            </div>

            <div class="col">
              {{ convertSecondsToHoursAndMinutes(sumValues.skiTime) }}
            </div>

            <div class="col">
              {{ Math.trunc(sumValues.skiDistance / 100) / 10 }} км
            </div>

            <div class="col">
              +{{ sumValues.skiClimb }} м
            </div>
          </div>
        </div>

        <div
          v-if="sumValues.walkTime > 0"
          class="mb-1"
        >
          <div class="row no-gutters align-center">
            <div class="col shrink">
              <v-icon
                color="black"
                class="mr-2"
                size="20"
              >
                mdi-walk
              </v-icon>
            </div>

            <div class="col">
              {{ convertSecondsToHoursAndMinutes(sumValues.walkTime) }}
            </div>

            <div class="col">
              {{ Math.trunc(sumValues.walkDistance / 100) / 10 }} км
            </div>

            <div class="col">
              +{{ sumValues.walkClimb }} м
            </div>
          </div>
        </div>

        <div
          v-if="sumValues.hikingTime > 0"
          class="mb-1"
        >
          <div class="row no-gutters align-center">
            <div class="col shrink">
              <v-icon
                color="black"
                class="mr-2"
                size="20"
              >
                mdi-hiking
              </v-icon>
            </div>

            <div class="col">
              {{ convertSecondsToHoursAndMinutes(sumValues.hikingTime) }}
            </div>

            <div class="col">
              {{ Math.trunc(sumValues.hikingDistance / 100) / 10 }} км
            </div>

            <div class="col">
              +{{ sumValues.hikingClimb }} м
            </div>
          </div>
        </div>

        <div
          v-if="sumValues.yogaTime > 0"
          class="mb-1"
        >
          <div class="d-flex align-center">
            <v-icon
              color="black"
              class="mr-2"
              size="20"
            >
              self_improvement
            </v-icon>

            {{ convertSecondsToHoursAndMinutes(sumValues.yogaTime) }}
          </div>
        </div>

        <div
          v-if="sumValues.workoutTime > 0"
          class="mb-1"
        >
          <div class="d-flex align-center">
            <v-icon
              color="black"
              class="mr-2"
              size="20"
            >
              fitness_center
            </v-icon>

            {{ convertSecondsToHoursAndMinutes(sumValues.workoutTime) }}
          </div>
        </div>

        <div v-if="sumValues.allTime > 0">
          <v-divider class="mt-2 mb-2 primary" />
          <div class="d-flex align-center">
            <v-icon
              color="black"
              class="mr-2"
              size="20"
            >
              mdi-clock-time-four-outline
            </v-icon>

            {{ convertSecondsToHoursAndMinutes(sumValues.allTime) }}
          </div>
        </div>

        <div v-else>
          <div class="d-flex align-center">
            <v-icon
              color="black"
              class="mr-2"
              size="20"
            >
              mdi-clock-time-four-outline
            </v-icon>

            0ч. 0м.
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <v-skeleton-loader
        type="image"
        max-height="92"
      />
    </div>
  </div>
</template>

<script>
  import { convertSecondsToHoursAndMinutes } from '@/helpers/move';

  export default {
    name: 'UserCalendarRowSummary',

    props: {
      sumValues: {
        type: [Object],
        default: () => ({}),
      },

      loadingRow: {
        type: [Boolean],
        default: false,
      },
    },

    methods: {
      convertSecondsToHoursAndMinutes,
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarSummary';

  @include calendarSummaryCommon;
</style>
