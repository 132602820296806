<template>
  <div class="app-calendar-header">
    <div
      v-for="(item, key) in days"
      :key="key"
      class="app-calendar-header__item"
    >
      <span class="app-calendar-header__title text-caption">
        {{ item }}
      </span>
    </div>

    <div class="app-calendar-header__item app-calendar-summary">
      <span class="app-calendar-header__title text-caption">
        Сводка
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserCalendarHeader',

    props: {
      startDate: {
        type: [Object, Number],
        default: null,
      },
    },

    data: () => ({
      days: [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
      ],
    }),
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarHeader';
  @include calendarHeaderCommon;
</style>
