<template>
  <div class="app-calendar-event">
    <div class="app-calendar-event__header">
      <div class="app-calendar-event__title text-caption">
        {{ $moment(item.start_date).format('DD MMMM YYYY') }}
      </div>

      <v-menu
        bottom
        left
        nudge-bottom="24"
      >
        <template #activator="{ on }">
          <v-icon
            class="ml-auto"
            color="black"
            size="20"
            v-on="on"
          >
            mdi-dots-vertical
          </v-icon>
        </template>

        <template>
          <v-list dense>
            <v-list-item
              @click="$emit('edit', item)"
            >
              Редактировать
            </v-list-item>

            <v-list-item
              @click="$emit('delete', item)"
            >
              Удалить
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </div>

    <div
      v-if="daysLeft"
      class="text-caption grey--text"
    >
      {{ daysLeft }}
    </div>

    <v-divider class="my-2" />

    <div class="app-calendar-event__name">
      <a
        v-if="item.link"
        :href="item.link"
        target="_blank"
        class="text-caption font-weight-medium lh-1"
      >
        {{ item.name }}
      </a>

      <span
        v-else
        class="text-caption font-weight-medium lh-1"
      >
        {{ item.name }}
      </span>
    </div>

    <div
      v-if="item.distance"
      class="lh-1 text-caption mt-2"
    >
      {{ item.distance + ' км' }}
    </div>

    <div
      v-if="item.description"
      class="d-flex align-center c-pointer mb-1 mt-2"
      @click="descriptionOpen = !descriptionOpen"
    >
      <span class="text-caption text-decoration-underline lh-1">
        Комментарий
      </span>

      <v-icon
        color="black"
        small
        class="ml-auto mr-2"
      >
        {{ descriptionOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </div>

    <div
      v-if="item.description && descriptionOpen"
      class="text-caption"
      v-html="description"
    />
  </div>
</template>

<script>
  import { getNoun, nl2br } from '@/helpers/string';

  export default {
    name: 'UserProfileEvent',

    props: {
      item: {
        type: [Object],
        default: null,
      },
    },

    data: () => ({
      descriptionOpen: false,
    }),

    computed: {
      daysLeft() {
        const days = this.$moment(this.item.start_date).diff(this.$moment().startOf('day'), 'days');

        if (days < 0) {
          return null;
        }

        if (days === 0) {
          return '(сегодня)';
        }

        if (days === 1) {
          return '(завтра)';
        }

        const noun = getNoun(['день', 'дня', 'дней'], days);

        return `(через ${days} ${noun})`;
      },

      description() {
        return nl2br(this.item.description);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/styles/components/calendarEvent';

  @include calendarEventCommon;

  .app-calendar-event {
    background: $color-event;
  }

  .app-calendar-event__header {
    margin-bottom: 12px;
  }
</style>
